<template>
  <div class="cores" v-loading.fullscreen.lock="loading">
    <el-card class="task-card" shadow="never" v-loading="taskLoading">
      <div slot="header" class="clearfix">
        <span>GPT Task</span>
        <el-button icon="el-icon-refresh" style="float: right; padding: 3px 0" type="text"
          @click="fetchTask">Refresh</el-button>
      </div>
      <el-row class="task-row">
        <el-col :span="2"><span class="cnt-title">ALL</span></el-col>
        <el-col :span="4" v-for="(t, i) in task.all" :key="i">
          <el-tag size="small" :type="t | statusFilter">{{ t.status }}</el-tag>
          <span class="cnt-text">{{ t.cnt }}</span>
        </el-col>
      </el-row>
      <el-row class="task-row">
        <el-col :span="2"><span class="cnt-title">Today</span></el-col>
        <el-col :span="4" v-for="(t, i) in task.today" :key="i">
          <el-tag size="small" :type="t | statusFilter">{{ t.status }}</el-tag>
          <span class="cnt-text">{{ t.cnt }}</span>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="task-card" shadow="never" v-loading="coreLoading">
      <div slot="header" class="clearfix">
        <span>GPT Core</span>
        <el-button icon="el-icon-setting" style="float: right; padding: 3px 0" type="text"
          @click="openSettingDialog">Setting</el-button>
        <el-button icon="el-icon-refresh" style="float: right; padding: 3px 0; margin-right: 20px" type="text"
          @click="fetchCore">Refresh</el-button>
      </div>
      <el-row>
        <el-table size="small" :data="core.cores">
          <el-table-column prop="uuid" label="Uuid" min-width="120"></el-table-column>
          <el-table-column prop="location" label="Location" min-width="50">
            <template slot-scope="scope">
              <span>{{ scope.row.location === 'local' ? 'Local' : scope.row.location === 'cloud' ? 'AliCloud' : '-'
                }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="specs" label="Specs" min-width="90"></el-table-column>
          <el-table-column prop="podStatus" label="Pod Status" min-width="50">
            <template slot-scope="scope">
              <span>{{
                scope.row.podStatus ? scope.row.podStatus : "-"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="APP Status" min-width="50">
            <template slot-scope="scope">
              <div class="status-dot"
                :class="scope.row.status === 'ready' ? 'success' : scope.row.status === 'offline' ? 'danger' : scope.row.status === 'init' ? 'warning' : 'info'"
                effect="dark"></div>
              <span style="margin-left: 10px">{{
                scope.row.status === 'ready' ? 'Ready' : scope.row.status === 'offline' ? 'Offline' : scope.row.status
                  === 'init' ? 'Init' : '-'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Last Exist At" min-width="90">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{
                scope.row.lastExistAt | displayTime()
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Last Active At" min-width="90">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{
                scope.row.lastActiveAt | displayTime()
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Created At" min-width="90">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{
                scope.row.createdAt | displayTime()
                }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <el-dialog title="AliCloud core replicas strategy setting" :visible.sync="dialogSettingVisible">
      <el-tabs v-model="coreStrategy.mode">
        <el-tab-pane label="Fixed" name="fix">
          <el-input-number size="mini" v-model="coreStrategy.fixNum" :min="0" :max="200" label="Num"></el-input-number>
        </el-tab-pane>
        <el-tab-pane label="Task adaptive" name="task">
          <el-form class="task-adaptive-form" size="mini">
            <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
              @click="addStrategyKey()">
            </el-button>
            <el-form-item v-for="(item, idx) in coreStrategy.taskItems" :key="idx">
              <el-col :span="2">
                <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                  @click="removeStrategyKey(idx)">
                </el-button>
              </el-col>
              <el-col class="row-input" :span="22">
                <span>Task Threshold</span>
                <el-input-number class="row-item-input" v-model="item.threshold" :min="0"
                  :max="99999"></el-input-number>
                <span>Replica Num</span>
                <el-input-number class="row-item-input" v-model="item.num" :min="0" :max="20"></el-input-number>
              </el-col>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer">
        <el-button size="small" @click="dialogSettingVisible = false">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmStrategySetting">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchGptTask, fetchGptCore, fetchCoreStrategy, updateCoreStrategy } from "@/api/admin";

export default {
  data() {
    return {
      task: {},
      core: {},
      taskLoading: false,
      coreLoading: false,
      dialogSettingVisible: false,
      coreStrategy: {
        mode: "fix", // or task
        fixNum: 0,
        taskItems: []
      },
      loading: false
    };
  },
  methods: {
    active() {
      this.fetchTask();
      this.fetchCore();
      this.getCoreStrategy();
    },
    fetchTask() {
      this.taskLoading = true;
      fetchGptTask().then(response => {
        if (response.success) {
          this.task = response.result;
        }
      }).finally(() => {
        this.taskLoading = false;
      })
    },
    fetchCore() {
      this.coreLoading = true;
      fetchGptCore().then(response => {
        if (response.success) {
          this.core = response.result;
        }
      }).finally(() => {
        this.coreLoading = false;
      })
    },
    openSettingDialog() {
      this.dialogSettingVisible = true;
    },
    getCoreStrategy() {
      fetchCoreStrategy().then(response => {
        if (response.success) {
          if (response.result && (response.result.mode == 'fix' ||
            response.result.mode == 'task' && response.result.taskItems.length > 0)) {
            if (response.result.taskItems == null) {
              response.result.taskItems = [];
            }
            this.coreStrategy = response.result;
          } else {
            // init value
            this.coreStrategy = {
              mode: "fix",
              fixNum: 0,
              taskItems: []
            }
          }
        }
      });
    },
    confirmStrategySetting() {
      this.$confirm("This will update core setting, continue ?", "Confirm setting", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.loading = true;
        // update core setting
        updateCoreStrategy(this.coreStrategy).then(response => {
          if (response.success) {
            this.$notify({
              title: "Update core setting successful",
              type: "success",
            });
            this.dialogSettingVisible = false;
          }
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    addStrategyKey() {
      this.coreStrategy.taskItems.push({
        threshold: 0,
        num: 1,
      });
    },
    removeStrategyKey(idx) {
      this.coreStrategy.taskItems.splice(idx, 1);
    },
  },
  filters: {
    statusFilter(info) {
      if (!info) {
        return "info";
      }
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[info.status];
    },
  },
};
</script>

<style lang="scss" scoped>
.cores {
  .task-card {
    margin-bottom: 10px;
    border: none;
    border-radius: 0;

    .task-row {
      margin-bottom: 10px;

      .cnt-title {
        vertical-align: middle;
      }

      .cnt-text {
        color: #606266;
        margin-left: 10px;
        display: inline;
        vertical-align: middle;
      }
    }
  }

  /deep/ .el-card__header {
    padding: 10px 20px;
    font-weight: bold;

    div {
      position: relative;
    }
  }



  .status-info {
    margin-top: 20px;
    color: #606266;
  }

  .task-adaptive-form {
    .add-botton {
      margin-bottom: -10px;
    }

    .add-botton {
      margin-bottom: 18px;
      display: block
    }

    .row-input {
      margin-top: 1px;

      .row-item-input {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
  }

  .status-dot.success {
    background-color: #67c23a;
  }

  .status-dot.danger {
    background-color: #f56c6c;
  }

  .status-dot.warning {
    background-color: #e6a23c;
  }

  .status-dot.info {
    background-color: #909399;
  }
}
</style>